<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户联系人">
          <el-input v-model="dataForm.customerContactName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker v-model="dataForm.takeGoodsTime" type="daterange" align="right" unlink-panels
            range-separator="-" size="small" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="供应商名称">
          <el-input v-model="dataForm.motorcadeName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客服状态" prop="status">
          <el-select class="item-choose" v-model="dataForm.careStatus" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option label="已审核" value="2"></el-option>
            <el-option label="未审核" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主管状态" prop="deptStatus">
          <el-select class="item-choose" v-model="dataForm.deptStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已审核" value="1"></el-option>
            <el-option label="未审核" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="财务状态" prop="status">
          <el-select class="item-choose" v-model="dataForm.status" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已审核" value="2"></el-option>
            <el-option label="未审核" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select class="item-choose" v-model="dataForm.orderStatus" size="small" @change="getDataList()" filterable
            clearable placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in dictTypeMap.orderStatusMap" :key="item.id" :label="item.name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="共赢车辆" prop="allWinVehicle">
          <el-select class="item-choose" v-model="dataForm.allWinVehicle" style="width: 100%;" filterable clearable
            placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="newAuth('order:cost:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" @click="operatopnList('pass')" type="primary" v-if="newAuth('order:cost:audit')"
              :disabled="selectFeeData.length ? false : true">财务审核通过</el-button>
          </div>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" @click="operatopnList('dept')" type="info" v-if="newAuth('request:deptStatus:audit')" :disabled="selectFeeData.length ? false : true">主管核对</el-button>
          </div>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" @click="operatopnList('care')" type="success" v-if="newAuth('order:cost:auditCare')"
                       :disabled="selectFeeData.length ? false : true">客服核对</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small" ref="tableRef"
                :height="tableHeight"
                :span-method="arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                @selection-change="handleSelectionChange"
                v-loading="dataListLoading" style="width: 100%;">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true" prop="deliveryTimeNode" fixed="left" />
        <el-table-column label="客户名称" prop="customerNameNode" width="70" :show-overflow-tooltip="true" fixed="left" />
        <el-table-column label="司机车牌" prop="vehiclePlateNode" width="70" :show-overflow-tooltip="true" fixed="left" />
        <el-table-column label="进仓单号" prop="inWarehouseNoNode" width="80" :show-overflow-tooltip="true" />
        <el-table-column label="订单类型" :formatter="orderType" prop="orderTypeNode" width="60"
          :show-overflow-tooltip="true" />
        <el-table-column label="订单车型" :formatter="dictConvertVehicle" width="80" :show-overflow-tooltip="true"
          prop="vehicleTypeNode" />
        <el-table-column label="报关方式" :formatter="entranceType" prop="entranceTypeNode" width="80"
          :show-overflow-tooltip="true" />
        <el-table-column label="提货方" prop="factoryName" width="100" :show-overflow-tooltip="true" />
        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true" />
        <el-table-column label="收货方" prop="factoryNameNode" width="100" :show-overflow-tooltip="true" />
        <el-table-column label="收货地址" prop="fullAddressNode" width="180" :show-overflow-tooltip="true" />
        <el-table-column label="公里数(KM)" prop="orderInfo.distance" :formatter="getDistanceValue" width="80"
          :show-overflow-tooltip="true" />
        <el-table-column v-if="isFreightFeeShow" label="费用小计" prop="costInfo.totalMoney" :formatter="getNumValue"
          width="80" :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column v-if="isFreightFeeShow" label="运费" prop="costInfo.freightFee" :formatter="getNumValue"
          width="70" :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="报关/入仓费" prop="costInfo.entranceFee" :formatter="getNumValue" width="75"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="高速费" prop="costInfo.highFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="过磅费" prop="costInfo.weighFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="装卸费" prop="costInfo.loadFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="押夜/超时费" prop="costInfo.overtimeFee" :formatter="getNumValue" width="75"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="提货费" prop="costInfo.takeFee" width="50" :formatter="getNumValue"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="加点费" prop="costInfo.addFee" width="50" :formatter="getNumValue"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="停车费" prop="costInfo.parkingFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="其他费用" prop="costInfo.otherFee" :formatter="getNumValue" width="55"
          :show-overflow-tooltip="true" class-name="green-cell" />
        <el-table-column label="其他费用备注" prop="costInfo.otherFeeRemark" :show-overflow-tooltip="true"
          class-name="green-cell" />
        <el-table-column label="异常说明" prop="groupExplain" width="60" :show-overflow-tooltip="true" />
        <el-table-column v-if="isFreightFeeShow" label="费用小计" prop="payCostInfo.auditTotalMoney"
          :formatter="getNumValue" width="80" :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column v-if="isFreightFeeShow" label="运费" prop="payCostInfo.freightFee" :formatter="getNumValue"
          width="60" :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="报关/入仓费" prop="payCostInfo.entranceFee" :formatter="getNumValue" width="75"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="高速费" prop="payCostInfo.highFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="过磅费" prop="payCostInfo.weighFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="装卸费" prop="payCostInfo.loadFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="押夜/超时费" prop="payCostInfo.overtimeFee" :formatter="getNumValue" width="70"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="提货费" prop="payCostInfo.takeFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="加点费" prop="payCostInfo.addFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="停车费" prop="payCostInfo.parkingFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="打印费" prop="payCostInfo.printFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="维修费" prop="payCostInfo.maintainFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="现金加油费" prop="payCostInfo.refuelFee" :formatter="getNumValue" width="70"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="现金过路费" prop="payCostInfo.passingFee" :formatter="getNumValue" width="70"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="油卡费" prop="payCostInfo.refuelCardFee" :formatter="getNumValue" width="65"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="粤通卡费" prop="payCostInfo.passingCardFee" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="其他费用" prop="payCostInfo.otherFee" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" class-name="yellow-cell" />
        <el-table-column label="其他费用备注" prop="payCostInfo.otherFeeRemark" :show-overflow-tooltip="true"
          class-name="yellow-cell" />
        <el-table-column label="司机借支" prop="payCostInfo.driverBorrowing" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" />
        <el-table-column label="扣款" prop="payCostInfo.deductionsFee" :formatter="getNumValue" width="40"
          :show-overflow-tooltip="true" />
        <el-table-column label="装卸费" prop="driverRoyalty.loadFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="住宿费" prop="driverRoyalty.stayFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="加班费" prop="driverRoyalty.extraFee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="司机提成费" prop="driverRoyalty.royaltyFee" :formatter="getNumValue" width="70"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="基本工资" prop="driverRoyalty.baseFee" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="绩效工资" prop="driverRoyalty.meritsFee" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="使用APP奖励" prop="driverRoyalty.appFee" :formatter="getNumValue" width="80"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="租房费" prop="driverRoyalty.other1Fee" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="油耗奖励" prop="driverRoyalty.other2Fee" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="司机费用小计" prop="driverRoyalty.payableAmount" :formatter="getNumValue" width="80"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="请假扣款" prop="driverRoyalty.accumulationFund" :formatter="getNumValue" width="50"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="扣减安全奖" prop="driverRoyalty.safetyAward" :formatter="getNumValue" width="70"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="扣减违章/油耗" prop="driverRoyalty.oilConsumption" :formatter="getNumValue" width="90"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="分摊水电费" prop="driverRoyalty.hydropower" :formatter="getNumValue" width="70"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="个税" prop="driverRoyalty.personalIncomeTax" :formatter="getNumValue" width="40"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="司机借支" prop="driverRoyalty.driverBorrowing" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="扣社保/公积金" prop="driverRoyalty.deductionsFee" :formatter="getNumValue" width="85"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="其他费用" prop="driverRoyalty.otherExpenses" :formatter="getNumValue" width="60"
          :show-overflow-tooltip="true" class-name="blue-cell" />
        <el-table-column label="费用图片" prop="costImage" width="60" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, costImageList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40" :show-overflow-tooltip="true" />
        <el-table-column label="体积" prop="orderGoods.volume" width="40" :show-overflow-tooltip="true" />
        <el-table-column label="重量" prop="orderGoods.weight" width="40" :show-overflow-tooltip="true" />
        <el-table-column label="指派车型" prop="vehiclePlan.vehicleTypeName" width="80" :show-overflow-tooltip="true" />
        <el-table-column label="司机姓名" prop="vehiclePlan.driverName" width="60" :show-overflow-tooltip="true" />
        <el-table-column label="司机电话" prop="vehiclePlan.driverPhone" width="90" :show-overflow-tooltip="true" />
        <el-table-column label="客户单号" prop="orderInfo.customerSelfNo" width="100" :show-overflow-tooltip="true" />
        <el-table-column label="客户联系人" prop="orderInfo.customerContactName" width="60" :show-overflow-tooltip="true" />
        <el-table-column label="客户联系电话" prop="orderInfo.customerContactPhone" width="90"
          :show-overflow-tooltip="true" />
        <el-table-column label="下单时间" prop="orderInfo.commitTime" width="120" :show-overflow-tooltip="true" />
        <el-table-column label="下单人" prop="orderInfo.commitName" width="70" :show-overflow-tooltip="true" />
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="110" :show-overflow-tooltip="true" />
        <el-table-column label="车次号" prop="vehiclePlan.planNo" width="110" :show-overflow-tooltip="true" />
        <el-table-column prop="status" label="订单状态" width="60" :show-overflow-tooltip="true" align="center"
          fixed="right">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">未提交</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="warning">待审核</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="warning">调度中</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small">已调度</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="success">已完成</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">异常结束</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">审核拒绝</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="danger">订单退回</el-tag>
            <el-tag
              v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1"
              size="small" type="info">已取消</el-tag>
            <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
            <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
            <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
            <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
            <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="客服状态" fixed="right" width="60" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.careStatus == 2" size="small" type="success">已审核</el-tag>
            <el-tag v-else size="small" type="danger">未审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="主管状态" fixed="right" width="60" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.deptStatus == 1" size="small" type="success">已审核</el-tag>
            <el-tag v-else size="small" type="danger">未审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="财务状态" fixed="right" width="60" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.costInfo.status == 2" size="small" type="success">已审核</el-tag>
            <el-tag v-else size="small" type="danger">未审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="确认状态" width="70" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.payCostInfo != null && scope.row.payCostInfo.confirmStatus == 2" size="small" type="danger">司机待确认</el-tag>
            <el-tag v-else-if="scope.row.payCostInfo != null && scope.row.payCostInfo.confirmStatus == 3" size="small" type="success">司机已确认</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('order:cost:find')" type="text" size="small"
                @click="operatopnList('cost', 'view', scope.row)">详情</el-button>
              <el-button v-if="newAuth('order:cost:audit') && scope.row.costInfo.status !== 2" type="text" size="small"
                @click="operatopnList('pass', 'edit', scope.row)">费用审核</el-button>
              <el-button v-if="newAuth('request:deptStatus:audit') && scope.row.orderInfo.deptStatus !== 1" type="text" size="small"
                         @click="operatopnList('dept', 'edit', scope.row)">主管审核</el-button>
              <el-button v-if="newAuth('order:cost:update')" type="text" size="small"
                @click="operatopnList('cost', 'edit', scope.row)">费用修改</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        background :page-sizes="[20, 50, 100]" :page-size="pageSize" :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <!-- 核销弹框 -->
    <receipt-off-popup ref="receiptOff" @refreshDataList="getDataList"></receipt-off-popup>
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import costModification from './detail/costModification-popup'
import receiptOffPopup from './detail/receipt-off-popup'
import viewImage from '@/views/commonPop/viewImage'
import qs from 'qs'
import store from '@/store'
export default {
  data () {
    return {
      dataForm: {
        takeGoodsTime: null,
        planNo: null,
        status: null,
        orderType: null,
        vehicleType: null,
        vehiclePlate: null,
        inWarehouseNo: null,
        customerContactName: null,
        customerName: null,
        motorcadeName: null,
        factoryName: null,
        driverName: null,
        allWinVehicle: null,
        customerIdList: [],
        orderStatus: null,
        careStatus: null,
        receiptStatus: null
      },
      tableHeight: '65vh',
      costType: 1,
      listType: 0,
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      costImageList: [7050, 7051, 7052, 7053, 7054, 7055, 7056, 7057, 7001, 7002, 7003, 7004, 7005, 7006, 7007, 7008],
      orderException: [6020],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectFeeData: [],
      receiptOffVisible: false,
      isFreightFeeShow: false, // 默认不可见
      spanArr: []
    }
  },
  components: {
    costModification,
    viewImage,
    receiptOffPopup

  },
  created () {
    // 判断是否查询所属客户
    if (this.newAuth('order:cost:customer')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    if (this.newAuth('order:cost:freightFee')) {
      this.isFreightFeeShow = true
    }
    this.setDefaultDate()
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100) !important'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      if ((this.getIndex) === rowIndex) {
        return 'selected-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    // 查看照片
    viewPhoto (row, types) {
      this.$refs.viewPhoto.init(row.costInfo.id, types)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (column.property && column.property.indexOf('otherFeeRemark') > -1) {
            return ''
          }
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && (column.property.indexOf('costInfo.') > -1 || column.property.indexOf('payCostInfo.') > -1 || column.property.indexOf('driverRoyalty.') > -1)) {
            if (column.property.indexOf('driverRoyalty.') > -1 && !item.driverRoyalty) {
              return 0
            }
            let val = column.property.split('.')
            return this.getValue(Number(item[val[0]][val[1]]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getDistanceValue (row, cell, value) {
      return parseFloat((value * 0.001).toFixed(1))
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (row) {
      let costInfo = row.costInfo || {}
      let payCostInfo = row.payCostInfo || {}
      let driverRoyalty = row.driverRoyalty || {}
      for (let c in costInfo) {
        costInfo[c] = this.getValue(costInfo[c])
      }
      for (let c in payCostInfo) {
        payCostInfo[c] = this.getValue(payCostInfo[c])
      }
      for (let c in driverRoyalty) {
        driverRoyalty[c] = this.getValue(driverRoyalty[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '', '', '', '',
        // row.profitTotal,
        costInfo.totalMoney,
        costInfo.freightFee,
        costInfo.entranceFee,
        costInfo.highFee,
        costInfo.weighFee,
        costInfo.loadFee,
        costInfo.overtimeFee,
        costInfo.takeFee,
        costInfo.addFee,
        costInfo.parkingFee,
        costInfo.otherFee, '', '',
        payCostInfo.auditTotalMoney,
        payCostInfo.freightFee,
        payCostInfo.entranceFee,
        payCostInfo.highFee,
        payCostInfo.weighFee,
        payCostInfo.loadFee,
        payCostInfo.overtimeFee,
        payCostInfo.takeFee,
        payCostInfo.addFee,
        payCostInfo.parkingFee,
        payCostInfo.printFee,
        payCostInfo.maintainFee,
        payCostInfo.refuelFee,
        payCostInfo.passingFee,
        payCostInfo.refuelCardFee,
        payCostInfo.passingCardFee,
        payCostInfo.otherFee,
        '',
        payCostInfo.driverBorrowing,
        payCostInfo.deductionsFee,
        driverRoyalty.loadFee,
        driverRoyalty.stayFee,
        driverRoyalty.extraFee,
        driverRoyalty.royaltyFee,
        driverRoyalty.baseFee,
        driverRoyalty.meritsFee,
        driverRoyalty.appFee,
        driverRoyalty.other1Fee,
        driverRoyalty.other2Fee,
        driverRoyalty.payableAmount,
        driverRoyalty.driverBorrowing,
        driverRoyalty.deductionsFee,
        driverRoyalty.accumulationFund,
        driverRoyalty.safetyAward,
        driverRoyalty.oilConsumption,
        driverRoyalty.hydropower,
        driverRoyalty.personalIncomeTax, driverRoyalty.otherExpenses]
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)


    },
    receiptUpdate (receiptStatus, row) {
      if (receiptStatus === 1) { // 回单撤销
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【回单撤销】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/order/receiptUpdate'),
            method: 'post',
            data: {
              ids: ids,
              receiptStatus: receiptStatus
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (receiptStatus === 2) { // 回单寄出
        this.receiptOffVisible = true
        let ids = []
        this.selectFeeData.forEach(item => {
          if (item.orderInfo.receiptStatus !== receiptStatus) {
            ids.push(item.orderInfo.id)
          }
        })
        if (ids.length === 0) {
          this.$message.error('没有符合寄出的数据，请检查是否勾选正确')
          return
        }
        this.$refs.receiptOff.init(receiptStatus, ids)
      }
    },
    operatopnList (type, todo, row) {
      if (type === 'pass') {
        // this.$refs.pass.init()
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【费用审核通过】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/costInfo/orderCost/audit'),
            method: 'post',
            data: {
              ids: ids,
              status: 2
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (type === 'cost') {
        this.$refs.cost.init(type, todo, row)
      } else if (type === 'care') {
        let ids = []
        if (row) {
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            ids.push(item.orderInfo.id)
          })
        }
        this.$confirm('正在执行【客服审核通过】操作，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/order/careStatus/audit'),
            method: 'post',
            data: {
              ids: ids,
              status: 2
            }
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => { })
      } else if (type === 'dept') {
        let ids = []
        let check = true
        if (row) {
          if (row.orderInfo.careStatus != 2) {
            this.$message.error('无法审核,需客服审核后才可操作!')
            check = false
          }
          ids.push(row.orderInfo.id)
        } else {
          this.selectFeeData.forEach(item => {
            if (item.orderInfo.careStatus != 2) {
              this.$message.error('无法审核,需客服审核后才可操作!')
              check = false
            }
            ids.push(item.orderInfo.id)
          })
        }
        if (check) {
          this.$confirm('正在执行【主管审核通过】操作，是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$http({
              url: this.$http.adornUrl('/order/deptStatus/audit'),
              method: 'post',
              data: {
                ids: ids,
                status: 2
              }
            }).then(({ data }) => {
              if (data) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1000,
                  onClose: () => {
                    this.getDataList()
                  }
                })
              } else {
                this.$message.error('操作失败')
              }
            })
          }).catch(() => { })
        }
      }
    },
    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/costExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': this.listType,
          'costType': this.costType,
          'orderType': this.dataForm.orderType,
          'status': this.dataForm.status,
          'factoryName': this.dataForm.factoryName,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'customerContactName': this.dataForm.customerContactName,
          'customerName': this.dataForm.customerName,
          'vehiclePlan.planNo': this.dataForm.planNo,
          'vehiclePlan.vehicleTypeId': this.dataForm.vehicleType,
          'vehiclePlan.driverName': this.dataForm.driverName,
          'vehiclePlan.motorcadeName': this.dataForm.motorcadeName,
          'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
          'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '订单费用核对表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'costType': this.costType,
        'orderType': this.dataForm.orderType,
        'status': this.dataForm.status,
        'factoryName': this.dataForm.factoryName,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'customerContactName': this.dataForm.customerContactName,
        'customerName': this.dataForm.customerName,
        'vehiclePlan.planNo': this.dataForm.planNo,
        'vehiclePlan.vehicleTypeId': this.dataForm.vehicleType,
        'vehiclePlan.driverName': this.dataForm.driverName,
        'vehiclePlan.motorcadeName': this.dataForm.motorcadeName,
        'vehiclePlan.vehiclePlate': this.dataForm.vehiclePlate,
        'vehiclePlan.allWinVehicle': this.dataForm.allWinVehicle,
        'customerIdList': this.dataForm.customerIdList,
        'orderStatus': this.dataForm.orderStatus,
        'careStatus': this.dataForm.careStatus,
        'receiptStatus': this.dataForm.receiptStatus,
        'deptStatus': this.dataForm.deptStatus,
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/costInfo/audit/findList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          const columns = this.$refs.tableRef.columns.map(column => column.property).filter(item => !!item)
          this.getSpanArr(data.list, columns)
          this.dataList = data.list
          this.totalPage = data.total
          await this.getAllSummaries(data.auditCost || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
       this.tableHeight = '67vh'
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    }
  }
}
</script>
<style lang="less">
div#pane-costManagement-chargeablePool {
}
</style>
